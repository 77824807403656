import { TabsProvider } from '../TabsContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export var Tabs = function Tabs(_ref) {
  var variant = _ref.variant,
      children = _ref.children;
  return _jsx(TabsProvider, {
    value: {
      variant: variant
    },
    children: children
  });
};
export default Tabs;