import Base from './Tabs';
export * from './Tabs';
import Control from './Control';
import Panel from './Panel';
import Track from './Track';
Base.displayName = "Tab.Base";
Control.displayName = "Tab.Control";
Panel.displayName = "Tab.Panel";
Track.displayName = "Tab.Track";
export { Control, Panel, Track };
export * from './TabsContext';
export default Base;